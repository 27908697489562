<template>
  <v-data-table
    :headers="headers"
    :items="productList"
    :options.sync="options"
    :server-items-length="totalProducts"
    :loading="loading"
    :footer-props="{
      'items-per-page-options': $store.getters['nav/itemsPerPageOptions'],
      'disable-pagination': loading,
      'disable-items-per-page': loading,
    }"
    :mobile-breakpoint="100"
    class="unset-table-hover"
    @update:items-per-page="updateItemsPerPage"
    @update:page="getProductsForApprove"
    @update:sort-desc="getProductsForApprove"
    :items-per-page="globalItemsPerPage"
  >
    <template v-slot:loading>
      <v-skeleton-loader type="table-row-divider@5" style="width: 90vw; max-width: 1390px"></v-skeleton-loader>
    </template>
    <template #[`header.source_link`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.store`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.cog`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`item.id`]="{item}">
      <router-link :to="{name: 'admin-product', params:{id: item.id}}">{{ item.id }}</router-link>
    </template>
    <template #[`item.store`]="{item}">
      <div class="d-flex flex-column">
        <v-tooltip v-if="cutString(item.store).includes('...')" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <router-link 
              @click.stop=""
              :to="{name: 'user', params: {id: item.vendor_id, tab: 'store'}}"
            >
              <span
                v-bind="attrs"
                v-on="on"
                style="white-space: nowrap"
              >
                {{ cutString(item.store) }}
              </span>
            </router-link>
          </template>
          <span>{{ item.store }}</span>
        </v-tooltip>
        <v-tooltip v-else top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
              <router-link 
                v-bind="attrs"
                v-on="on"
                @click.stop=""
                :to="{name: 'user', params: {id: item.vendor_id, tab: 'store'}}"
              >
              <span
                v-bind="attrs"
                v-on="on"
              >
                {{ cutString(item.store) }}
              </span>
              </router-link>
          </template>
          <span>{{ 'Store'}}</span>
        </v-tooltip>
        <v-tooltip v-if="cutString(item.vendor).includes('...')" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <router-link
              v-bind="attrs"
              v-on="on"
              :to="{name: 'user', params: {id: item.vendor_id}}"
            >
              <span 
                v-bind="attrs"
                v-on="on"
              >
                {{ cutString(item.vendor) }}
              </span>
            </router-link>
          </template>
          <span>{{ item.vendor }}</span>
        </v-tooltip>
        <v-tooltip v-else top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <router-link 
              v-bind="attrs"
              v-on="on"
              :to="{name: 'user', params: {id: item.vendor_id}}"
            >
              <span 
                v-bind="attrs"
                v-on="on"
              >
                {{ cutString(item.vendor) }}
              </span>
            </router-link>
          </template>
          <span>{{ 'Vendor'}}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.image`]="{item}">
      <router-link :to="{name: 'admin-product', params:{id: item.id}}">
        <v-img
          max-height="50"
          max-width="70"
          class="my-2"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;border-radius: 5px"
          :src="item.image"
        />
      </router-link>
    </template>
    <template #[`item.source_link`]="{item}">
      <div class="d-flex flex-column">
        <v-tooltip v-if="item.asin" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <a 
              v-bind="attrs"
              v-on="on"
              :href="item.product_url"
              target="_blank"
            >
              {{ item.asin }}
            </a>
          </template>
          <span>{{ 'ASIN'}}</span>
        </v-tooltip>
        <v-tooltip v-if="item.source_link" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="product-link-wrap"
              v-bind="attrs"
              v-on="on"
            >
              <a 
                :href="item.source_link"
              >
                {{ item.source_link }}
              </a>
            </div>
          </template>
          <span>{{ 'Source'}}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.cog`]="{item}">
      <div class="d-flex flex-column">
        <v-tooltip v-if="item.cog" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              style="white-space: nowrap"
            >
              <b>COG:</b> {{ cCodeToSymbol[item.currency_code] + Number(item.cog) }}
            </span>
          </template>
          <span>{{ 'COG'}}</span>
        </v-tooltip>
        <v-tooltip v-if="item.third_pl" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              style="white-space: nowrap"
            >
              <b>3PL:</b> {{ Number(item.third_pl) ? cCodeToSymbol[item.currency_code] + Number(item.third_pl) : '-' }}
            </span>
          </template>
          <span>{{ '3PL'}}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.profit_fba`]="{item}">
      <div class="d-flex flex-column align-start">
        <v-tooltip v-if="item.profit_fba" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              small
              label
              class="v-chip-light-bg"
              :class="{'error--text': item.profit_fba < 0, 'success--text': item.profit_fba > 0}"
              :color="`${item.profit_fba > 0 ? 'success' : 'error'}`"
            >
              {{ Number(item.profit_fba) ? cCodeToSymbol[item.currency_code] + Number(item.profit_fba) : '-' }}
            </v-chip>
          </template>
          <span>{{ 'Profit'}}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.offer`]="{item}">
      <v-tooltip top color="secondary" open-delay="300">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              :color="!item.offer || item.offer.in_stock == null? 'secondary':item.offer.in_stock?'success':'error'"
              dark
              v-bind="attrs"
              v-on="on"
          >
            {{icons.mdiPackageVariantClosed}}
          </v-icon>
        </template>
        <span v-if="!item.offer || item.offer.in_stock == null">Stock not parsed</span>
        <span v-else-if="item.offer.in_stock">Product in stock</span>
        <span v-else>Product out of stock</span>
      </v-tooltip>
      <v-tooltip top color="secondary" open-delay="300">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              :color="!item.offer || !item.offer.price? 'secondary':item.offer.price == item.cog?'success':'error'"
              dark
              v-bind="attrs"
              v-on="on"
          >
            {{icons.mdiCash}}
          </v-icon>
        </template>
        <span v-if="!item.offer || !item.offer.price">Price not parsed</span>
        <span v-else-if="item.offer.price == item.cog"></span>
        <span v-else>Price is different <br/> item price: {{item.cog}}<br/> parsed price: {{item.offer.price}}</span>
      </v-tooltip>
      <v-tooltip top color="secondary" open-delay="300">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              :color="!item.brand || !item.offer || !item.offer.brand? 'secondary':item.offer.brand.toLowerCase() == item.brand.toLowerCase()?'success':'error'"
              dark
              v-bind="attrs"
              v-on="on"
          >
            {{icons.mdiCardBulletedOutline}}
          </v-icon>
        </template>
        <span v-if="!item.brand || !item.offer || !item.offer.brand">Brand not parsed</span>
        <span v-else-if="item.offer.brand.toLowerCase() == item.brand.toLowerCase()">Brand confirmed<br/>item brand: {{item.brand}}<br/>parsed brand: {{item.offer.brand}}</span>
        <span v-else>Brand is different<br/>item brand: {{item.brand}}<br/>parsed brand: {{item.offer.brand}}</span>
      </v-tooltip>
    </template>
    <template #[`item.access`]="{item}">
      <div class="d-flex justify-space-between align-center">
        <div class="mr-1">
          <v-btn color="success" x-small @click="changeStatus(item.id,2, item.market_id)">{{ $t('Verify') }}</v-btn>
        </div>
        <div class="mr-1">
          <v-dialog
            v-model="dialog[item.id]"
            persistent
            max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                color="warning"
                v-bind="attrs"
                v-on="on"
                x-small
              >
                {{ $t('Reject') }}
              </v-btn>
            </template>
            <v-card>
              <v-form ref="form" :v-model="valid">
                <v-card-title class="pb-0">
                  <span class="text-h5">{{ $t('Reject Product') }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-radio-group v-model="rejectInfo">
                          <span v-for="item in rejectVariants.slice(0, 8)" class="m-2">
                            <v-radio
                              :key="item.id"
                              :label="$t(item.title)"
                              :value="$t(item.title)"
                              @click="rejectNote = item.description"
                            />
                          </span>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="6">
                      <v-radio-group v-model="rejectInfo">
                          <span v-for="item in rejectVariants.slice(8)" class="m-2">
                            <v-radio
                              :key="item.id"
                              :label="$t(item.title)"
                              :value="$t(item.title)"
                              @click="rejectNote = item.description"
                            />
                          </span>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-textarea
                      height="150"
                      outlined
                      name="input"
                      label="Description"
                      v-model="rejectNote"
                    />
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="dialog = []"
                  >
                    {{ $t('Close') }}
                  </v-btn>
                  <v-btn
                      color="error"
                      text
                      @click="changeStatus(item.id,3, item.market_id)"
                  >
                    {{ $t('Reject') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </div>
        <div class="mr-1">
          <v-dialog
              v-model="dialogBan[item.id]"
              persistent
              max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error"
                     v-bind="attrs"
                     v-on="on"
                     x-small>
                {{ $t('Ban') }}
              </v-btn>
            </template>
            <v-card>
              <v-form ref="form" :v-model="valid">
                <v-card-title>
                  <span class="text-h5">{{ $t('Ban Product') }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                            outlined
                            name="input"
                            :rules="noteRules"
                            label="Rejection reason"
                            v-model="rejectNote"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogBan = []"
                  >
                    {{ $t('Close') }}
                  </v-btn>
                  <v-btn
                      color="error darken-1"
                      text
                      @click="changeStatus(item.id,4, item.market_id)"
                  >
                    {{ $t('Ban') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </div>
        <div>
          <v-btn
            :to="{name:'admin-product', params:{id: item.id}}"
            icon
          >
            <v-icon size="25">
              {{ icons.mdiEyeOutline }}
            </v-icon>
          </v-btn>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiMenuDown,
  mdiEyeOutline,
  mdiCardBulletedOutline,
  mdiCash, mdiPackageVariantClosed
} from '@mdi/js'
import {getCurrentInstance, ref, watch, computed} from "@vue/composition-api"
import axios from "@axios"
import store from '@/store'
import {kitCut, getRejectVariants, cCodeToSymbol} from "@/plugins/helpers"
import {useRouter} from '@core/utils'
import editProduct from "@/views/cabinet/product/EditProduct"
import DeleteProduct from "@/views/cabinet/product/DeleteProduct"

export default {
  components: {
    DeleteProduct,
    editProduct
  },
  setup() {
    const { route, router } = useRouter()
    const vm = getCurrentInstance().proxy
    const options = computed(({
      get() {
        return store.state.pagination.adminProducts[route.value.params.tab]
      },
      set(val) {
        let ob = {}
        ob[route.value.params.tab] = Object.assign({}, val, {
          statusTab: route.value.params.tab,
          search: val.search
        })
        store.commit("pagination/SET_ADMIN_PRODUCTS_PAGINATION", ob);
      }
    }))
    const dialog = ref([])
    const rejectNote = ref('')
    const noteRules = [v => !!v || 'Reject note required', v => v.length > 1 || 'Minimal length of characters is 1']
    const valid = ref(false)
    const form = ref(null)
    const dialogBan = ref([])
    const rejectVariants = computed(() => getRejectVariants())
    const rejectInfo = ref('')
    const rejectFullNote = computed(() => {
      if(rejectInfo.value) {
        return rejectInfo.value + '. ' + rejectNote.value
      }
      return rejectNote.value
    })
    const cutString = (text, limit = 18) => {
      return kitCut(text, limit)
    }
    const changeStatus = (id, status, market_id) => {
      if (form.value) {
        form.value.validate()
      }
      if ((status == 3 || status == 4) && !valid) {
        return
      }
      axios.post(`admin/product-approve/${id}`, {
        status,
        market_id,
        note: rejectFullNote.value,
        _method: 'put'
      }).then(res => {
        getProductsForApprove()
        let statusText = 'updated'
        switch (status) {
          case 2:
            statusText = 'approved';
            break;
          case 3:
            statusText = 'rejected';
            break;
          case 4:
            statusText = 'banned';
            break;
        }
        vm.$notify({
          group: 'deals',
          title: vm.$t(`Product ${statusText}`),
          type: 'success'
        })
        dialog.value = []
      }).catch()
    }

    watch(() => dialog.value, () => {
      rejectNote.value = ''
      rejectInfo.value = ''
    })

    const getProductsForApprove = () => store.dispatch('product/getProductsForApprove', options.value)

    const globalItemsPerPage = computed(() => store.getters['nav/globalItemPerPage'])
    const updateItemsPerPage = (e) => {
      store.commit("nav/SET_GLOBAL_ITEMS_PER_PAGE", e);
      getProductsForApprove()
    }

    getProductsForApprove()

    return {
      cutString,
      rejectNote,
      rejectInfo,
      rejectVariants,
      updateItemsPerPage,
      globalItemsPerPage,
      changeStatus,
      getProductsForApprove,
      valid,
      form,
      noteRules,
      rejectNote,
      options,
      loading: computed(() => store.state.product.loading),
      totalProducts: computed(() => store.state.product.total),
      dialog,
      dialogBan,
      headers: [
        {text: 'Date', value: 'created_at', width: 100},
        {text: 'ID', value: 'id', width: 65, align: 'center'},
        {text: 'Picture', value: 'image', width: 95},
        // {text: 'Vendor', value: 'vendor', width: 95},
        {text: 'Store<br>Vendor', value: 'store',  width: 160, sortable: false},
        // {text: 'Store', value: 'store', width: 95},
        // {text: 'ASIN', value: 'asin', width: 80},
        {text: 'ASIN<br>Source', value: 'source_link', width: 120},
        {text: 'COG<br>3PL', value: 'cog', width: 100 },
        // {text: 'COG', value: 'cog', width: 80},
        // {text: '3PL', value: 'third_pl', width: 80},
        {text: 'AutoCheck', value: 'offer', width: 120},
        {text: 'Profit', value: 'profit_fba', width: 40},
        {text: '', value: 'access', sortable: false, width: 120}
      ],
      productList: computed(() => store.state.product.products),
      cCodeToSymbol: cCodeToSymbol(),
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiMenuDown,
        mdiEyeOutline,
        mdiCardBulletedOutline,
        mdiCash,
        mdiPackageVariantClosed
      },
    }
  },
}
</script>