<template>
<div class="saa-products-container">
    <account-role-title role="Admin" />
    <!-- <breadcrumbs /> -->
    <ProductsInfo :admin="true" :reload="reload" />
    <span class="title">{{ $t("Inventory") }}</span>
    <v-tabs v-model="tab" show-arrows class="deals-tabs">
        <v-tab v-for="(tab, index) in tabs" :key="index" @click="$router.push({name:'admin-products-list', params:{tab:tab.route}, query:{t: new Date().getTime()}})">
            <span>{{ $t(tab.title) }}</span>
        </v-tab>
        <!-- tabs item -->
        <v-tabs-items v-model="tab">
            <v-tab-item v-for="(tab, index) in tabs" :key="index">
                <div class="d-flex justify-end">
                    <products-search v-if="tab.route !== 'unverified' && tab.route !== 'unverified-new'" />
                </div>
                <!-- tab component -->
                <component v-if="tab.route === $route.params.tab" :is="tab.component" @productDeleted="evReload"/>
            </v-tab-item>
        </v-tabs-items>
    </v-tabs>
</div>
</template>

<script>
import ProductsInfo from "@/views/cabinet/product/ProductsInfo";
import ProductsSearch from "@/views/cabinet/product/ProductsSearch";
import AdminProductsTable from "@/views/cabinet/product/product-list/AdminProductsTable";
import ProductListToVerify from "@/views/cabinet/product/product-list/ProductListToVerify";
import CreateProduct from "@/views/cabinet/product/CreateProduct";
import { ref, watch, computed } from "@vue/composition-api";
import { useRouter } from '@core/utils'
import axios from "@axios";
import store from '@/store';
import ProductListToVerifyV2 from "@/views/cabinet/product/product-list/ProductListToVerifyV2";

export default {
    name: "AdminProductsList",
    components: {
        ProductsSearch,
        CreateProduct,
        AdminProductsTable,
        ProductsInfo,
        ProductListToVerify,
        ProductListToVerifyV2
    },
    setup() {
        const reload = ref(false)
        const tabs = [
            { title: 'All', route: 'all', component: AdminProductsTable },
            { title: 'Active', route: 'active', component: AdminProductsTable },
            { title: 'Inactive', route: 'inactive', component: AdminProductsTable },
            { title: 'Drafts', route: 'drafts', component: AdminProductsTable },
            { title: 'Rejected', route: 'rejected', component: AdminProductsTable },
            { title: 'Unverified', route: 'unverified', component: ProductListToVerify },
            { title: 'Unverified New', route: 'unverified-new', component: ProductListToVerifyV2 }
        ]
        const { router, route } = useRouter()
        const tab = ref(tabs.findIndex(tab => tab.route === route.value.params.tab))
        watch(() => route.value.params.tab, (newTab) => tab.value = tabs.findIndex(tab => tab.route === newTab))
        return {
            evReload: () => reload.value = !reload.value,
            reload,
            tab,
            tabs
        }
    }
}
</script>
