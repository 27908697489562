var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"seller-products-table",attrs:{"headers":_vm.headers,"items":_vm.productList,"options":_vm.options,"server-items-length":_vm.totalProducts,"loading":_vm.loading,"footer-props":{
    'items-per-page-options': _vm.$store.getters['nav/itemsPerPageOptions'],
    'disable-pagination': _vm.loading,
    'disable-items-per-page': _vm.loading,
  },"mobile-breakpoint":100,"items-per-page":_vm.globalItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":_vm.updateItemsPerPage,"update:page":_vm.getProductsFromApi,"update:sort-desc":_vm.getProductsFromApi},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{staticStyle:{"width":"90vw","max-width":"1390px"},attrs:{"type":("table-row-divider@" + (_vm.$store.getters['nav/globalItemPerPage']))}})]},proxy:true},{key:"header.source_link",fn:function(ref){
  var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.status",fn:function(ref){
  var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.buy_box_price",fn:function(ref){
  var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.market_title",fn:function(ref){
  var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"item.id",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'admin-product', params: {id: item.id}}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.vendor",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'user', params: {id: item.vendor_id}}}},[_vm._v(_vm._s(item.vendor))])]}},{key:"item.market_title",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"white-space":"nowrap"}},[(_vm.cutString(item.market_title).includes('...'))?_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":{name: 'user', params: {id: item.vendor_id, tab: 'store'}}}},[_c('span',_vm._g(_vm._b({staticStyle:{"white-space":"nowrap"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.cutString(item.market_title))+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.market_title))])]):_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":{name: 'user', params: {id: item.vendor_id, tab: 'store'}}}},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.cutString(item.market_title))+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Store')))])]),_c('div',{staticClass:"d-flex align-center justify-center"},[_c('rating',{attrs:{"id-rating":item.market_rating}})],1),(_vm.cutString(item.vendor).includes('...'))?_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":{name: 'user', params: {id: item.vendor_id}}}},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.cutString(item.vendor))+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.vendor))])]):_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":{name: 'user', params: {id: item.vendor_id}}}},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.cutString(item.vendor))+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Vendor')))])])],1)]}},{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column align-center"},[(item.status == 3 || item.status == 4)?_c('v-tooltip',{attrs:{"max-width":300,"color":"secondary","top":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"v-chip-light-bg mb-1",class:((_vm.statusColor[_vm.status[item.status]]) + "--text"),attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.status[item.status])+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.icons.mdiInformation))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Reason'))+": "+_vm._s(item.reject_note))])]):_c('v-chip',{staticClass:"v-chip-light-bg mb-1",class:((_vm.statusColor[_vm.status[item.status]]) + "--text"),attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")]),_c('v-tooltip',{attrs:{"max-width":300,"color":"secondary","top":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:{'text-red': item.sold_times >= 3}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.sold_times)+"/3 ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Sold')))])])],1)]}},{key:"item.image",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'admin-product', params: {id: item.id}}}},[_c('v-img',{staticClass:"my-2",staticStyle:{"box-shadow":"rgba(0, 0, 0, 0.15) 0px 2px 8px","border-radius":"5px"},attrs:{"max-height":"50","max-width":"70","src":item.image}})],1)]}},{key:"item.asin",fn:function(ref){
  var item = ref.item;
return [_c('a',{attrs:{"href":item.product_url,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(item.asin))])]}},{key:"item.is_fragile",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"mb-1 d-flex"},[_c('red-flags',{attrs:{"hasVariations":!!item.has_variations,"bundle":!!item.bundle,"isFragile":!!item.is_fragile,"isWater":!!item.is_meltable,"isBiohazard":!!item.is_haz_mat,"isPackage":!!item.is_oversize,"isAlert":!!item.is_alert,"isAmazon":!!item.is_amazon,"isAdult":!!item.is_adult,"isSmallAndLight":!!item.is_small_and_light}})],1)])]}},{key:"item.source_link",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[(item.asin)?_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":item.product_url,"target":"_blank"}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.asin)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s('ASIN'))])]):_vm._e(),(_vm.cutString(item.source_link).includes('...'))?_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300","max-width":"330px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":item.source_link,"target":"_blank"}},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.cutString(item.source_link))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.source_link.slice(0, 100)))])]):_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":item.source_link,"target":"_blank"}},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.cutString(item.source_link))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s('Source'))])])],1)]}},{key:"item.price",fn:function(ref){
  var item = ref.item;
return [_c('v-list-item',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(("$" + (Number(item.price)))))])]}},{key:"item.third_pl",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.third_pl) ? _vm.cCodeToSymbol[item.currency_code] + Number(item.third_pl) : '-')+" ")]}},{key:"item.buy_box_price",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"font-size":"12px"}},[_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(Number(item.buy_box_price) ? _vm.cCodeToSymbol[item.currency_code] + Number(item.buy_box_price) : _vm.$t("No BuyBox")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s('BuyBox price'))])]),_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('b',[_vm._v("COG:")]),_vm._v(" "+_vm._s(_vm.cCodeToSymbol[item.currency_code] + Number(item.cog)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s('COG'))])]),_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('b',[_vm._v("3PL:")]),_vm._v(" "+_vm._s(Number(item.third_pl) ? _vm.cCodeToSymbol[item.currency_code] + Number(item.third_pl) : '-'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s('3PL'))])])],1)]}},{key:"item.profit_fba",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column align-start"},[(item.profit_fba)?_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"v-chip-light-bg",class:{'error--text': item.profit_fba < 0, 'success--text': item.profit_fba > 0},attrs:{"small":"","label":"","color":("" + (item.profit_fba > 0 ? 'success' : 'error'))}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(Number(item.profit_fba) ? _vm.cCodeToSymbol[item.currency_code] + Number(item.profit_fba) : '-')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s('Profit'))])]):_vm._e()],1)]}},{key:"item.eye",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{name: 'admin-product', params: {id: item.id}}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s('View'))])])]}},{key:"item.access",fn:function(ref){
  var item = ref.item;
return [_c('edit-product',{attrs:{"id":item.id,"open":_vm.openEdit[item.id]!==undefined?_vm.openEdit:_vm.stopTrigger},on:{"productEdited":function($event){return _vm.getProductsFromApi()}}}),_c('delete-product',{attrs:{"id":item.id,"is-dialog-visible":_vm.openDelete[item.id]!==undefined?_vm.openDelete:_vm.stopTrigger,"market-id":item.market_id},on:{"productDeleted":function($event){_vm.getProductsFromApi();_vm.$emit('productDeleted')}}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"product-button-dots",attrs:{"dark":"","icon":"","plain":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[(item.status < 3)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.changeStatus(item.id, item.status)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.status == 0 ? _vm.$t('Publish') : _vm.$t('Unpublish')))])],1):_vm._e(),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.editProduct(item.id)}}},[_c('v-list-item-title',[_c('span',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t('Edit')))])])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.deleteProduct(item.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('Delete')))])],1)],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }