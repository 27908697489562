<template>
  <div class="saa-products-info-container">
      <div class="saa-products-info-block" v-for="(info, index) in productsInfo" :key="index">
        <svg class="shadow" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_ddd_2180_134172)">
            <rect x="3" y="2" width="40" height="40" rx="20" :fill="info.color"/>
            <path d="M29 16H27C27 13.79 25.21 12 23 12C20.79 12 19 13.79 19 16H17C15.9 16 15 16.9 15 18V30C15 31.1 15.9 32 17 32H29C30.1 32 31 31.1 31 30V18C31 16.9 30.1 16 29 16ZM21 20C21 20.55 20.55 21 20 21C19.45 21 19 20.55 19 20V18H21V20ZM23 14C24.1 14 25 14.9 25 16H21C21 14.9 21.9 14 23 14ZM27 20C27 20.55 26.55 21 26 21C25.45 21 25 20.55 25 20V18H27V20Z" fill="white"/>
          </g>
          <defs>
            <filter id="filter0_ddd_2180_134172" x="0" y="0" width="46" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="1"/>
              <feGaussianBlur stdDeviation="1.5"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.12 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2180_134172"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="1"/>
              <feGaussianBlur stdDeviation="0.5"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.14 0"/>
              <feBlend mode="normal" in2="effect1_dropShadow_2180_134172" result="effect2_dropShadow_2180_134172"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect3_dropShadow_2180_134172"/>
              <feOffset dy="2"/>
              <feGaussianBlur stdDeviation="0.5"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0"/>
              <feBlend mode="normal" in2="effect2_dropShadow_2180_134172" result="effect3_dropShadow_2180_134172"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2180_134172" result="shape"/>
            </filter>
          </defs>
        </svg>
        <div>
          <span class="info-title">{{ $t(info.title) }}</span></br>
          <span class="col">{{ info.count }}</span>
        </div>
      </div>
  </div>
</template>
<script>
import store from '@/store'
import {onMounted, computed, watch} from "@vue/composition-api";
import {useVModel} from '@vueuse/core'
import { useRouter } from '@core/utils'

export default {
  name: "ProductsInfo",
  props: {
    admin: {
      type: Boolean,
      default: false
    },
    reload: {
      type: Boolean,
      default: false
    }
  },
  watch: { 
    reload: function() {
      this.getProductsInfo(this.isAdminData)
    }
  },
  setup(props, { emit }) {
    const { route, router } = useRouter()
    const isAdminData = useVModel(props, 'admin', emit)
    const reloadPrp = useVModel(props, 'reload', emit) 
    const getProductsInfo = (isAdminData) => {
      if(!isAdminData) {
        store.dispatch('product/getProductsShortInfo')
      }else {
        store.dispatch('product/getAdminProductsShortInfo', {
          store_id: route.value.params.store_id
        })
      }
    }
    onMounted(() => {
      getProductsInfo(isAdminData.value)
    })
    return {
      getProductsInfo,
      isAdminData,
      productsInfo: computed(() => store.state.product.productsShortInfo),
    }
  }
}
</script>