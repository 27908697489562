<template>
  <v-dialog v-model="isDialogVisible" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('Edit Product') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <template>
            <v-skeleton-loader
              v-show="!productLoaded"
              type="article, actions"
            />
          </template>
          <v-form
            v-show="productLoaded"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              :disabled="loading"
              v-model="linkToAmazon"
              :rules="linkToAmazonRules"
              :label="$t('Link to Amazon')"
              required
              outlined
              dense
              hide-details="auto"
              class="mb-4"
            />
            <v-text-field
              :disabled="loading"
              v-model="supplierLink"
              :rules="supplierLinkRules"
              :background-color="!$vuetify.theme.isDark ? '#fdf5e6' : 'rgb(85, 80, 119)'"
              :label="$t('Supplier Link')"
              required
              outlined
              dense
              hide-details="auto"
              class="mb-4"
            />

            <v-row class="mb-1">
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  :disabled="loading"
                  v-model="cog"
                  :rules="cogRules"
                  :prefix="cCodeToSymbol[currencyCode]"
                  type="number"
                  :label="$t('COG')"
                  required
                  outlined
                  dense
                  hide-details="auto"
                />
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  :disabled="loading"
                  v-model="threePL"
                  :rules="thirdRules"
                  :prefix="cCodeToSymbol[currencyCode]"
                  type="number"
                  :label="$t('3PL')"
                  required
                  outlined
                  dense
                  hide-details="auto"
                />
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  :disabled="loading"
                  v-model="dealPrice"
                  :rules="priceRules"
                  prefix="$"
                  type="number"
                  :label="$t('Deal Price')"
                  required
                  outlined
                  dense
                  hide-details="auto"
                />
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="fees"
                  prefix="$"
                  type="number"
                  :label="$t('Fees')"
                  required
                  outlined
                  dense
                  disabled
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-checkbox
              class="mt-0"
              v-model="bundle"
              :label="$t('Set/Bundle')"
            />
            <v-textarea
              :disabled="loading"
              v-model="description"
              :label="$t('Description')"
              rows="2"
              outlined
              hide-details="auto"
              class="mb-4"
            />
            <v-textarea
              :disabled="loading"
              v-model="vendorNote"
              :label="$t('Vendor\'s Note')"
              rows="2"
              outlined
              hide-details="auto"
              :background-color="!$vuetify.theme.isDark ? '#fdf5e6' : 'rgb(85, 80, 119)'"
              class="mb-4"
            />
            <span class="p-1 d-block mb-2 secondary--text">{{ $t('This information will be available when the deal is purchased by the buyer.') }}</span>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions v-show="productLoaded">
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            outlined
            @click="isDialogVisible = false"
            :disabled="loading"
        >
          {{ $t('Close') }}
        </v-btn>
        <v-btn
            color="primary"
            @click="updateProduct"
            :loading="loading"
            :disabled="loading"
        >
          {{ $t('Update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ref, getCurrentInstance, watch} from '@vue/composition-api'
import axios from "@axios"
import {cCodeToSymbol, marketCurrCode} from "@/plugins/helpers"
import {useRouter} from "@core/utils"

export default {
  props: {
    id: Number,
    open: Object
  },
  emits: ['productEdited'],
  setup(props, {emit}) {
    const vm = getCurrentInstance().proxy
    const isDialogVisible = ref(false)
    const valid = ref(true)
    const form = ref(null)
    const loading = ref(false)
    const supplierLinkRules = [v => !!v || 'Supplier link required', v => validURLNotAmazon(v) || 'Invalid Supplier Link']
    const supplierLink = ref(null)
    const linkToAmazonRules = [v => !!v || 'Amazon link required', v => validURLAmazon(v) || 'Invalid Amazon Link']
    const linkToAmazon = ref(null)
    const currencyCode = ref('USD')
    const dealPrice = ref(null)
    const threePL = ref(null)
    const cog = ref(null)
    const bundle = ref(false)
    const priceRules = [v => !!v || 'Price required', v => v >= 1 || 'Minimum deal price $1']
    const thirdRules = [v => !!v || 'Third party logistics required', v => v >= 1 || 'Minimum 3PL amount $1']
    const cogRules = [v => !!v || 'Cost of goods required']
    const description = ref(null)
    const vendorNote = ref(null)
    const productLoaded = ref(false)
    const fees = ref(null)
    const {route} = useRouter()
    const marketId = ref(null)
    function validURLNotAmazon(str) {
      var expression = /^(?:(?:https?|ftp):\/\/)(?!.*amazon\.)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
      var pattern = new RegExp(expression);
      return !!pattern.test(str);
    }
    function validURLAmazon(str) {
      var expression = /(?:.amazon.*[/dp/]|$)([A-Z0-9]{10})/;
      var pattern = new RegExp(expression);
      if(typeof str === 'string') {
        let url = new URL(str)
        let hostname = url.host.replace('www.', '')
        currencyCode.value = marketCurrCode()[hostname]
      }
      return !!pattern.test(str);
    }
    const getProduct = () => {
      let url = route.value.name === 'admin-products-list' || route.value.name === 'admin-product' ? 'admin/product/' : 'seller-product/'
      axios.get(url + props.id).then(res => {
        supplierLink.value = res.data.data.source_link
        linkToAmazon.value = res.data.data.product_url
        cog.value = res.data.data.cog
        bundle.value = res.data.data.bundle
        dealPrice.value = res.data.data.price
        threePL.value = res.data.data.logistics
        description.value = res.data.data.description
        vendorNote.value = res.data.data.vendor_note
        fees.value = res.data.data.deal_fee
        marketId.value = res.data.data.market_id
        currencyCode.value = res.data.data.currency_code
        productLoaded.value = true
      }).catch(e => {
        vm.$notify({
          group: 'deals',
          title: 'Product Error',
          type: 'error',
          text: vm.$t("Product loading failed"),
        })
        loading.value = false
      })
    }
    const updateProduct = () => {
      if (!form.value.validate()) {
        return
      }
      loading.value = true
      let data = {
        product_url: linkToAmazon.value,
        source_link: supplierLink.value,
        cog: cog.value,
        bundle: bundle.value,
        logistics: threePL.value,
        price: dealPrice.value,
        description: description.value,
        vendor_note: vendorNote.value,
        _method: 'put'
      }
      let url = route.value.name === 'admin-products-list' || route.value.name === 'admin-product' ? 'admin/update-product/' : 'update-product/'
      if(route.value.name === 'admin-products-list' || route.value.name === 'admin-product') {
        data.market_id = marketId.value
      }
      axios.post(url+props.id, data)
          .then(res => {
            vm.$notify({
              group: 'deals',
              title: 'Product',
              type: 'success',
              text: vm.$t("Product successfully updated"),
            })
            loading.value = false
            isDialogVisible.value = false
            emit('productEdited')
          })
          .catch(e => {
            let message = e.response?.status !== 500 && e.response?.data?.message?e.response.data.message:vm.$t("Product update failed")
            vm.$notify({
              group: 'deals',
              title: 'Product Error',
              type: 'error',
              text: message,
            })
            loading.value = false
          })
    }

    watch(() => props.open, () => {
      if (Object.keys(props.open)[0] == props.id) {
        isDialogVisible.value = true
        getProduct()
      }
    }, {deep: true})
    return {
      currencyCode,
      cCodeToSymbol: cCodeToSymbol(),
      bundle,
      loading,
      productLoaded,
      isDialogVisible,
      supplierLinkRules,
      supplierLink,
      linkToAmazonRules,
      linkToAmazon,
      dealPrice,
      threePL,
      cog,
      description,
      vendorNote,
      priceRules,
      thirdRules,
      cogRules,
      fees,
      form,
      valid,
      getProduct,
      updateProduct,
    }
  },
}
</script>
