<template>
  <v-dialog
      v-model="isVisible"
      width="500"
  >
    <v-card :loading="deleteInProgress">
      <v-img max-height="300" max-width="300" :src="banner" style="margin: auto"></v-img>
      <v-card-title class="justify-center">
        {{ $t('Are you sure you want to delete the item?') }}
      </v-card-title>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
                color="primary"
                outlined
                @click="isVisible = false"
                block
            >
              No
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
                block
                color="primary"
                @click="deleteProduct()"
            >
              Yes
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {getCurrentInstance, ref, watch} from '@vue/composition-api'
import axios from "@axios";
import {useRouter} from "@core/utils";
export default {
  props: {
    isDialogVisible: Object,
    id: Number,
    marketId: {
      type: Number,
      require: false
    }
  },
  emits: ['productDeleted'],
  setup(props, {emit}) {
    const vm = getCurrentInstance().proxy
    const isVisible = ref(false)
    const deleteInProgress = ref(false)
    const {route} = useRouter()
    watch(() => props.isDialogVisible, () => {
      if (Object.keys(props.isDialogVisible)[0] == props.id) {
        isVisible.value = true
      }
    }, {deep: true})
    const deleteProduct = () => {
      deleteInProgress.value = true
      let url = route.value.name === 'admin-products-list' || route.value.name === 'admin-product' ? 'admin/delete-product/' : 'delete-product/'
      axios.post(url+props.id,{_method:'delete', market_id: props.marketId}).then(res => {
        vm.$notify({
          group: 'deals',
          title: vm.$t('Product Deleted'),
          type: 'success',
        })
        isVisible.value = false
        emit('productDeleted')
      }).catch()
    }
    return {
      isVisible,
      deleteInProgress,
      deleteProduct,
      banner: require('@/assets/images/app/business-man-depressed-stressed-watching-decrease-graph-stock-financial-trade-market-diagram_1150-39760 1.png')
    }
  },
}
</script>
