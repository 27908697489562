<template>
  <v-data-table
    :headers="headers"
    :items="productList"
    :options.sync="options"
    :server-items-length="totalProducts"
    :loading="loading"
    :footer-props="{
      'items-per-page-options': $store.getters['nav/itemsPerPageOptions'],
      'disable-pagination': loading,
      'disable-items-per-page': loading,
    }"
    class="seller-products-table"
    :mobile-breakpoint="100"
    @update:items-per-page="updateItemsPerPage"
    @update:page="getProductsFromApi"
    @update:sort-desc="getProductsFromApi"
    :items-per-page="globalItemsPerPage"
  >
    <template v-slot:loading>
      <v-skeleton-loader :type="`table-row-divider@${$store.getters['nav/globalItemPerPage']}`" style="width: 90vw; max-width: 1390px"></v-skeleton-loader>
    </template>
    <template #[`header.source_link`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.status`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.buy_box_price`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.market_title`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`item.id`]="{item}">
      <router-link :to="{name: 'admin-product', params: {id: item.id}}">{{ item.id }}</router-link>
    </template>
    <template #[`item.vendor`]="{item}">
      <router-link :to="{name: 'user', params: {id: item.vendor_id}}">{{ item.vendor }}</router-link>
    </template>
    <template #[`item.market_title`]="{item}">
      <div class="d-flex flex-column" style="white-space: nowrap">
        <v-tooltip v-if="cutString(item.market_title).includes('...')" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <router-link :to="{name: 'user', params: {id: item.vendor_id, tab: 'store'}}">
              <span
                v-bind="attrs"
                v-on="on"
                style="white-space: nowrap"
              >
                {{ cutString(item.market_title) }}
              </span>
            </router-link>
          </template>
          <span>{{ item.market_title }}</span>
        </v-tooltip>
        <v-tooltip v-else top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
              <router-link :to="{name: 'user', params: {id: item.vendor_id, tab: 'store'}}">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ cutString(item.market_title) }}
                </span>
              </router-link>
          </template>
          <span>{{ $t('Store') }}</span>
        </v-tooltip>
        <div class="d-flex align-center justify-center">
          <rating :id-rating="item.market_rating" />
        </div>
        <v-tooltip v-if="cutString(item.vendor).includes('...')" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <router-link :to="{name: 'user', params: {id: item.vendor_id}}">
              <span 
                v-bind="attrs"
                v-on="on"
              >
                {{ cutString(item.vendor) }}
              </span>
            </router-link>
          </template>
          <span>{{ item.vendor }}</span>
        </v-tooltip>
        <v-tooltip v-else top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <router-link :to="{name: 'user', params: {id: item.vendor_id}}">
              <span 
                v-bind="attrs"
                v-on="on"
              >
                {{ cutString(item.vendor) }}
              </span>
            </router-link>
          </template>
          <span>{{ $t('Vendor') }}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.status`]="{item}">
      <div class="d-flex flex-column align-center">
        <v-tooltip :max-width="300" color="secondary" top v-if="item.status == 3 || item.status == 4" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              small
              :color="statusColor[status[item.status]]"
              :class="`${statusColor[status[item.status]]}--text`"
              class="v-chip-light-bg mb-1"
              v-bind="attrs"
              v-on="on"
            >
              {{ status[item.status] }}
              <v-icon class="ml-1" x-small>{{ icons.mdiInformation }}</v-icon>
            </v-chip>
          </template>
          <span>{{ $t('Reason') }}: {{item.reject_note}}</span>
        </v-tooltip>
        <v-chip
          v-else
          small
          :color="statusColor[status[item.status]]"
          :class="`${statusColor[status[item.status]]}--text`"
          class="v-chip-light-bg mb-1"
        >
          {{ status[item.status] }}
        </v-chip>
        <v-tooltip :max-width="300" color="secondary" top open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              :class="{'text-red': item.sold_times >= 3}"
            >
              {{ item.sold_times }}/3
            </div>
          </template>
          <span>{{ $t('Sold') }}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.image`]="{item}">
      <router-link :to="{name: 'admin-product', params: {id: item.id}}">
        <v-img
          class="my-2"
          max-height="50"
          max-width="70"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;border-radius: 5px"
          :src="item.image"
        />
      </router-link>
    </template>
    <template #[`item.asin`]="{item}">
      <a :href="item.product_url" target="_blank" @click.stop>{{ item.asin }}</a>
    </template>
    <template #[`item.is_fragile`]="{item}">
    <div class="d-flex flex-column align-center">
        <div class="mb-1 d-flex">
          <red-flags
            :hasVariations="!!item.has_variations"
            :bundle="!!item.bundle"
            :isFragile="!!item.is_fragile"
            :isWater="!!item.is_meltable"
            :isBiohazard="!!item.is_haz_mat"
            :isPackage="!!item.is_oversize"
            :isAlert="!!item.is_alert"
            :isAmazon="!!item.is_amazon"
            :isAdult="!!item.is_adult"
            :isSmallAndLight="!!item.is_small_and_light"
          />
        </div>
      </div>
    </template>
    <template #[`item.source_link`]="{item}">
      <div class="d-flex flex-column">
        <v-tooltip v-if="item.asin" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <a 
              v-bind="attrs"
              v-on="on"
              :href="item.product_url"
              target="_blank"
            >
              {{ item.asin }}
            </a>
          </template>
          <span>{{ 'ASIN' }}</span>
        </v-tooltip>
        <v-tooltip v-if="cutString(item.source_link).includes('...')" top color="secondary" open-delay="300" max-width="330px">
          <template v-slot:activator="{ on, attrs }">
            <a 
              v-bind="attrs"
              v-on="on"
              :href="item.source_link"
              target="_blank"
            >
              {{ cutString(item.source_link) }}
            </a>
          </template>
          <span>{{ item.source_link.slice(0, 100) }}</span>
        </v-tooltip>
        <v-tooltip v-else top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <a 
              v-bind="attrs"
              v-on="on"
              :href="item.source_link"
              target="_blank"
            >
              {{ cutString(item.source_link) }}
            </a>
          </template>
          <span>{{ 'Source' }}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.price`]="{item}">
      <v-list-item class="font-weight-semibold">{{ `$${Number(item.price)}` }}</v-list-item>
    </template>
    <template #[`item.third_pl`]="{item}">
      {{ Number(item.third_pl) ? cCodeToSymbol[item.currency_code] + Number(item.third_pl) : '-' }}
    </template>
    <template #[`item.buy_box_price`]="{item}">
      <div class="d-flex flex-column" style="font-size: 12px">
        <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ Number(item.buy_box_price) ? cCodeToSymbol[item.currency_code] + Number(item.buy_box_price) : $t("No BuyBox") }}</span>
          </template>
          <span>{{ 'BuyBox price'}}</span>
        </v-tooltip>
        <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"><b>COG:</b> {{ cCodeToSymbol[item.currency_code] + Number(item.cog) }}</span>
          </template>
          <span>{{ 'COG'}}</span>
        </v-tooltip>
        <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"><b>3PL:</b> {{ Number(item.third_pl) ? cCodeToSymbol[item.currency_code] + Number(item.third_pl) : '-' }}</span>
          </template>
          <span>{{ '3PL'}}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.profit_fba`]="{item}">
      <div class="d-flex flex-column align-start">
        <v-tooltip v-if="item.profit_fba" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              small
              label
              class="v-chip-light-bg"
              :class="{'error--text': item.profit_fba < 0, 'success--text': item.profit_fba > 0}"
              :color="`${item.profit_fba > 0 ? 'success' : 'error'}`"
            >
              {{ Number(item.profit_fba) ? cCodeToSymbol[item.currency_code] + Number(item.profit_fba) : '-' }}
            </v-chip>
          </template>
          <span>{{ 'Profit'}}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.eye`]="{item}">
      <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :to="{name: 'admin-product', params: {id: item.id}}"
            >
              <v-icon size="26">
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ 'View'}}</span>
        </v-tooltip>
    </template>
    <template #[`item.access`]="{item}">
      <edit-product
        :id="item.id"
        :open="openEdit[item.id]!==undefined?openEdit:stopTrigger"
        @productEdited="getProductsFromApi()"
      />
      <delete-product
        :id="item.id"
        :is-dialog-visible="openDelete[item.id]!==undefined?openDelete:stopTrigger"
        :market-id="item.market_id"
        @productDeleted="getProductsFromApi();$emit('productDeleted')"
      />
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            plain
            v-bind="attrs"
            v-on="on"
            class="product-button-dots"
          >
            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="item.status < 3" link @click="changeStatus(item.id, item.status)">
            <v-list-item-title>{{ item.status == 0 ? $t('Publish') : $t('Unpublish') }}</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="editProduct(item.id)">
            <v-list-item-title>
              <span class="mb-4">{{ $t('Edit') }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item link @click="deleteProduct(item.id)">
            <v-list-item-title>{{ $t('Delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import {mdiEyeOutline, mdiSquareEditOutline, mdiDotsVertical, mdiMenuDown, mdiInformation} from '@mdi/js'
import RedFlags from "@/views/market/components/RedFlags";
import {useVModel} from '@vueuse/core'
import {ref, watch, onMounted, computed} from "@vue/composition-api";
import {useRouter} from '@core/utils'
import store from '@/store'
import axios from "@axios";
import {kitCut, cCodeToSymbol} from "@/plugins/helpers"
import editProduct from "@/views/cabinet/product/EditProduct";
import DeleteProduct from "@/views/cabinet/product/DeleteProduct";
import useAppConfig from '@core/@app-config/useAppConfig'
import Rating from "@/views/market/components/Rating";

export default {
  name: 'AdminProductsTable',  
  components: {
    RedFlags,
    DeleteProduct,
    editProduct,
    Rating
  },
  setup(props, { emit }) {
    const { menuIsVerticalNavMini } = useAppConfig()
    const statusColor = {
      /* eslint-disable key-spacing */
      Verified: 'success',
      Rejected: 'error',
      Unverified: 'warning',
      Draft: 'blue-grey',
      /* eslint-enable key-spacing */
    }
    const { route, router } = useRouter()
    const options = computed(({
      get() {
        return store.state.pagination.adminProducts[route.value.params.tab]
      },
      set(val) {
        let ob = {}
        let page = val.page > val.lastPage ? 1 : val.page
        ob[route.value.params.tab] = Object.assign({}, val, {
          statusTab: route.value.params.tab,
          search: val.search ? val.search : route.value.query.search,
          page
        })
        store.commit("pagination/SET_ADMIN_PRODUCTS_PAGINATION", ob);
      }
    }))

    const publishLoading = ref('')
    const openEdit = ref({})
    const openDelete = ref({})
    const stopTrigger = ref({})

    const editProduct = (id) => {
      let num = openEdit.value[0] !== undefined ? openEdit.value[0] + 1 : 1
      openEdit.value = {[id]: num}
    }
    const cutString = (text, limit = menuIsVerticalNavMini.value ? 22 : 11) => {
      return kitCut(text, limit)
    }
    const deleteProduct = (id) => {
      let num = openDelete.value[0] !== undefined ? openDelete.value[0] + 1 : 1
      openDelete.value = {[id]: num}
    }

    const changeStatus = (id, status) => {
      axios.post(`update-product-status/${id}`,{status: status == 0 ? 1 : 0, _method: 'put'})
        .then(res => {
          publishLoading.value = id
          getProductsFromApi()
          //Update short info
          store.dispatch('product/getAdminProductsShortInfo')
        })
        .catch()
    }

    const getProductsFromApi = () => store.dispatch('product/getAdminProducts', options.value)

    const globalItemsPerPage = computed(() => store.getters['nav/globalItemPerPage'])
    const updateItemsPerPage = (e) => {
      store.commit("nav/SET_GLOBAL_ITEMS_PER_PAGE", e)
      getProductsFromApi()
    }

    getProductsFromApi()

    if(store.state.pagination.adminProducts[route.value.params.tab].search) {
      router.push({ name: route.value.name, query: { search: store.state.pagination.adminProducts[route.value.params.tab].search }})
    }

    return {
      cutString,
      updateItemsPerPage,
      globalItemsPerPage,
      getProductsFromApi,
      openEdit,
      openDelete,
      stopTrigger,
      changeStatus,
      editProduct,
      deleteProduct,
      options,
      loading: computed(() => store.state.product.loading),
      totalProducts: computed(() => store.state.product.total),
      headers: [
        {text: 'Date', value: 'created_at', width: 100},
        {text: 'ID', value: 'id', width: 65},
        {text: 'Picture', value: 'image', sortable: false, width: 60},
        {text: 'Status<br>Sold', value: 'status',  width: 110, sortable: false, align: 'center'},
        // {text: 'Status', value: 'status', width: 90, align: 'center'},
        // {text: 'Sold', value: 'sold_times', width: 60, sortable: false},
        {text: 'Store<br>Vendor', value: 'market_title', sortable: false, align: 'center'},
        {text: 'ASIN<br>Source', value: 'source_link',  sortable: false},
        {text: 'BuyBox price<br>COG&nbsp&nbsp3PL', value: 'buy_box_price', width: 120},
        // {text: 'BuyBox price', value: 'buy_box_price', width: 80},
        // {text: 'COG', value: 'cog', width: 80},
        // {text: '3PL', value: 'third_pl', width: 80},
        {text: 'Profit', value: 'profit_fba', width: 60, align: 'center'},
        {text: 'Red Flags', value: 'is_fragile', width: 100, sortable: false, align: 'center'},
        {text: 'Price', value: 'price', width: 80},
        {value: 'eye', sortable: false, align: 'end'},
        {text: '', value: 'access', sortable: false, width: 10}
      ],
      productList: computed(() => store.state.product.products),
      status: {
        0: 'Draft',
        1: 'Unverified',
        2: 'Verified',
        3: 'Rejected',
        4: 'Banned',
      },
      statusColor,
      cCodeToSymbol: cCodeToSymbol(),
      icons: {
        mdiEyeOutline,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiMenuDown,
        mdiInformation,
      },
    }
  },
}
</script>