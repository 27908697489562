<template>
<div class="search" :class="{'search-admin': !admin}">
    <v-text-field v-model="search" :label="$t('Search ...')" append-icon="mdi-magnify" class="mx-4" @input="getProducts($event)" style="margin-bottom: 0"></v-text-field>
</div>
</template>

<script>
import store from '@/store'
import {useVModel} from '@vueuse/core'
import {useRouter} from '@core/utils'
import {ref, computed} from "@vue/composition-api"

export default {
    name: 'ProductsSearch',
    props: {
        admin: {
            type: Boolean,
            default: true
        }
    },
    setup(props, {
        emit
    }) {
        const timer = ref(null)
        const { route, router } = useRouter()
        const search = computed(({
            get() {
                if(props.admin) {
                    return store.state.pagination.adminProducts[route.value.params.tab].search
                }else{
                    return store.state.pagination.products[route.value.params.tab].search
                }
            },
            set(val) {
                let ob = {}
                ob[route.value.params.tab] = {
                    search: val
                }
                if(props.admin) {
                    router.replace({ name: 'admin-products-list', query: { search: val } })
                    store.commit("pagination/SET_ADMIN_PRODUCTS_PAGINATION", ob);
                }else {
                    router.replace({ name: route.value.name === 'admin-products-user-store-list' ? 'admin-products-user-store-list' : 'seller-products', query: { search: val }, params: route.value.name === 'admin-products-user-store-list' ? {store_id: route.value.params.store_id} : {} })
                    store.commit("pagination/SET_PRODUCTS_PAGINATION", ob);
                }
            }
        }))
        const getProducts = function (e) {
            if (timer.value) {
                clearTimeout(timer.value)
                timer.value = null
            }
            timer.value = setTimeout(() => {
                store.dispatch(props.admin || route.value.name === 'admin-products-user-store-list'? 'product/getAdminProducts' : 'product/getSellerProducts', Object.assign({}, props.admin ? store.state.pagination.adminProducts[route.value.params.tab] : store.state.pagination.products[route.value.params.tab], {
                    search: e,
                    marketId: route.value.params.store_id,
                    page: 1
                }))
            }, 1000)
        }
        return {
            getProducts,
            search
        }
    }
}
</script>
